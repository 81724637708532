// 添加样式依赖
import $ from 'jquery';
import '@/common/css/index.less';
import './index.less';

console.log('他ta星球');
if (window.location.href.indexOf('buduoduo.com') >= 0) {
  $('.foot4').show();
} else if (window.location.href.indexOf('sdqcloud.com') >= 0) {
  $('.foot3').show();
} else if (window.location.href.indexOf('tataxingqiu.com') >= 0) {
  $('.foot1').show();
} else {
  $('.foot2').show();
}

$('.down-box .android').click(function() {
  // location.href = './func.html';
  window.location.href = 'https://download.tataxingqiu.com/download/taTaXingQiu_ttxq.apk';
});
$('.down-box .ios').click(function() {
  // location.href = './func.html';
  // window.location.href = 'https://www.apple.com.cn/app-store/';
  window.location.href = 'https://apps.apple.com/app/id1664036357';
});

$('.nav-box > div')
  .eq(0)
  .click(function() {
    location.href = './';
  });
$('.nav-box > div')
  .eq(1)
  .click(function() {
    location.href = './recharge.html';
  });
